<template>
  <div class="savoir_plus">
    <div class="link">
      <router-link v-if="to && ($store.state.user.logged || !login)" :to="to" tag="span">
        <button>{{ button_text }}</button>
      </router-link>
      <router-link v-else-if="to && !$store.state.user.logged" @click.native="$store.dispatch('user/beforeLogin', to.name)" :to="{ name: 'user_login' }" tag="span">
        <button>{{ button_text }}</button>
      </router-link>
      
      
      <a href="" @click.prevent="open = !open" v-if="!no_plus">En savoir plus</a>
    </div>

    <transition name="slide-fade">
    <div class="text" v-if="open">
      <slot></slot>
    </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false  
    }
  },
  props: [ 'to', 'button_text', 'no_plus', 'login' ],
  name: "savoir_plus"
}
</script>

<style scoped>
  .savoir_plus .text { font-size: 16px; line-height: 24px; background: #f5f5f5; padding: 16px 22px; margin-top: 16px; }
  .savoir_plus .link button { margin-right: 24px; background: transparent linear-gradient(90deg, #f23f3f 0%, #fb7f2a 100%) 0% 0% no-repeat padding-box; border-radius: 50px }

  @media only screen and (min-width: 800px) {
    .savoir_plus .text { font-size: 0.91vw; line-height: 1.5vw;  }
  }
</style>