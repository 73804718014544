<template>

  <div class="faq">
    <div class="t">
      Questions fréquentes
    </div>
    <div class="f">
      <el-collapse>
        <el-collapse-item title="Comment m’inscrire sur Monresto.click en tant que professionnel ?">
          <div>Cliquez sur l’onglet «créer mon restaurant», puis lisez la charte de qualité. Si vos prestations répondent aux exigences évoquées, acceptez la charte et les CGU/CGV.</div>
          <div>Puis suivez très simplement les étapes. </div>
        </el-collapse-item>
        <el-collapse-item title="Combien de temps va prendre l’inscription en ligne ?">
          <div>L’inscription sur la plateforme est simple et rapide. En moins de 5 mn, vous pouvez créer votre commerce en ligne et y référencer votre premier produit.</div>
          <div>Vous n’avez plus qu’à renseigner certains champs : Nom & Prénom du gérant, nom du restaurant, adresse…</div>
          <div>Si vous êtes référencés sur GOOGLE, vos photos et informations essentielles s’importent directement.</div>
        </el-collapse-item>
        <el-collapse-item title="Pourquoi faut-il donner certaines informations personnelles avec la solution de paiement Stripe ?">
          <div>Les informations personnelles sont utiles à l’opérateur bancaire pour lutter contre le blanchiment d’argent et la fraude financière.</div>
          <div>Les informations données garantissent la sécurité de vos paiements.</div>
        </el-collapse-item>
        <el-collapse-item title="Est-ce que j’aurai « la main » sur le contenu éditorial de mon resto virtuel ?">
          <div>Bien sûr vous disposez d’un espace personnalisable sur la plateforme :</div>
          <div>Vous pourrez afficher des coordonnées, vos horaires d’ouverture, mettre des photographies illustrant votre activité et vos produits.</div>
        </el-collapse-item>
        <el-collapse-item title="Combien de produits puis je mettre en ligne ?">
          <div>Vous pourrez mettre autant de produits en ligne que vous le souhaitez. Il n’y a  pas de limite.</div>
        </el-collapse-item>
        <el-collapse-item title="Quels sont les avantages d’une commande en ligne ?">
          <div>La commande en ligne permet de réduire le nombre d’erreurs puisque le client choisit et valide lui-même ses plats.</div>
          <div>Elle permet d’optimiser votre productivité, vous passez moins de temps sur la prise de commande (souvent compliquée par téléphone) et bénéficiez de davantage de temps pour la cuisine ou le service.</div>
        </el-collapse-item>
        <el-collapse-item title="Que se passe-t-il lorsque je reçois une commande ?">
          <div>Lorsqu'une nouvelle commande arrive, votre interface de gestion sur mobile sonne et s’accompagne d’une notification très visuelle,</div>
          <div>Votre commande est également notifiée immédiatement par email.</div>
        </el-collapse-item>
        <el-collapse-item title="Comment faire si je suis dans le rush et que je ne peux plus assurer mes commandes en ligne ?">
          <div>Pas de panique ! Il suffit de désactiver la commande en ligne sur votre interface de réception des commandes, et de la réactiver quand vous êtes de nouveau opérationnel.</div>
        </el-collapse-item>
        <el-collapse-item title="Je suis en rupture de stock sur un produit, que faire ?">
          <div>Il suffit de mettre le produit en "retrait de la vente" sur votre Interface.</div>
        </el-collapse-item>
        <el-collapse-item title="Puis-je mettre en place des offres promotionnelles ?">
          <div>Vous avez la possibilité d’appliquer des promotions sur n’importe quels produits de votre carte.</div>
        </el-collapse-item>
        <el-collapse-item title="Ma présence sur cette marketplace va-t-elle optimiser mon référencement ?">
          <div>Nous menons des actions de communication en continu (Référencement, moteurs de recherche, réseaux sociaux, blog, emailing…).</div>
          <div>Nous assurons la promotion active de votre restaurant sur sa zone de chalandise.</div>
        </el-collapse-item>
        <el-collapse-item title="Je figure déjà sur d’autres sites et réseaux sociaux, pourquoi me positionner plutôt sur une marketplace ">
          <div>Une marketplace est un lieu de transaction où se rencontrent vendeurs et acheteurs. Un intermédiaire qui n’a pour but que de rassembler l’offre et la demande. </div>
          <div>Les marketplaces offrent donc une visibilité incomparable. Certaines génèrent des milliers de visiteurs par jour. Être référencé sur une marketplace permet donc de cibler de nouveaux clients.</div>
          <div>Les plateformes garantissent la sécurité de paiement, autant  pour le vendeur que pour l’acheteur.</div>
          <div>L’investissement pour être visible sur une marketplace est inférieur à celui mobilisé  pour la création et la gestion de son propre site e-commerce.</div>
          <div>En faisant appel à une marketplace, le restaurateur se concentre sur son métier de base. C’est l’intermédiaire qui s’occupe de la vente, du marketing et des frais d’hébergement du site.</div>
          
        </el-collapse-item>
        <el-collapse-item title="Pourquoi la marketplace de MonResto.click plus qu’une autre ?">
          <div>Parce que figurer sur MONRESTO.CLICK c’est la possibilité d’accéder à:
          </div>
          <ul>
            <li>La plateforme “bon  marché”  du moment, monresto.click est une solution nouvelle génération simple, intuitive et performante, les coûts de commissionnement sont les plus bas du marché.</li>
            <li>Une démarche responsable et solidaire.</li>
            <li>Un service de référencement et de géolocalisation de votre établissement.</li>
            <li>Un nouveau canal de vente orienté « commerces de proximité »</li>
            <li>La garantie  de ne pas être noyé dans une concurrence excessive des mastodontes du marché.</li>
            <li>Des statistiques de vente détaillées qui vous permettront de développer des opérations marketing personnalisées, ciblées et optimisées</li>
            <li>Des conseils donnés par une équipe humaine et bienveillante.</li>
          </ul>
        </el-collapse-item>
        <el-collapse-item title="Puis-je proposer la livraison ?">
          <div>Oui si vous êtes en mesure d'assurer la livraison en interne, MonResto.click ne propose pas de livreurs/coursiers affiliés.</div>
          <div>Si vous proposez la livraison, vous pouvez tarifer ce service sur l'interface (en fonction de la distance). Vous pouvez fixer ce tarif en fonction d'un minimum d'achat.</div>
          <div>Retenez que l’option click&collect est suffisante pour figurer sur notre plateforme, l’objectif étant de favoriser les commerces de proximité. Les clients apprécient, la gratuité, la rapidité, le côté pratique, la certitude de récupérer le bon produit et la possibilité d’échanger sur place avec vous.</div>
        </el-collapse-item>
        <el-collapse-item title="Comment fonctionne l’ordre de présentation des restaurants ?">
          <div> La présentation est pour l’heure tout à fait aléatoire et peut changer en cours de journée, elle évoluera par la suite selon divers critères comme la distance par rapport au client, la moyenne des notes ou la popularité.</div> 
          <div>Un restaurant nouvellement inscrit bénéficie d’une mise en avant temporaire.</div> 
          <div>Des filtres permettent au client d’affiner l’ordre de présentation selon ses souhaits spécifiques.</div> 
          <div>Prochainement vous aurez la possibilité d’acheter une amélioration de position si vous estimez qu’elle est susceptible de favoriser vos commandes.</div> 
        </el-collapse-item>
        <el-collapse-item title="Combien me coûte Monresto.click ?">
<!--          <div>Le tarif de l’abonnement mensuel est de 49€ HT seulement.</div>-->
          <div>Les frais de commissionnement (couvrants frais bancaires et support technique) sont réduits à 8% HT + 0,25 cts par transaction.</div>
          <div>Il n’y a pas de frais d’inscription.</div>
          <div>Pas d’engagement (Vous pouvez quitter la plateforme à tout moment et sans frais).</div>
<!--          <div>OFFRE SPÉCIALE FERMETURE DES BARS ET RESTAURANTS : Pour toutes inscriptions réalisées avant le 28 février, deux  mois d’abonnements vous sont offerts (hors frais de commissionnement), à partir de votre date d’inscription.</div>-->
        </el-collapse-item>
        <el-collapse-item title="Ma question n’est pas dans la liste ?">
          <div>Contactez le service support <a href="mailto:info@monresto.click">info@monresto.click</a> et exposez votre problème, nous prendrons contact avec vous pour tenter de répondre à votre question le plus rapidement possible.</div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>

</template>

<script>
export default {
  name: "faq_restaurateur"
}
</script>
<style>
</style>
<style scoped>
  .faq { display: flex; justify-content: space-between; flex-direction: column; padding-bottom: 36px; }
  .faq .t { font-size: 24px; padding: 16px; font-weight: bold; }


  @media only screen and (min-width: 800px) {
    .faq { width: 70%; margin: auto; flex-direction: row }
    .faq .t { font-size: 2.5vw;}
    .faq .f { width: 70%; }
  }
</style>